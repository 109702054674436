import React from 'react';
import {
  CookieConsentBanner,
  triggerCookieConsentBanner,
} from '@porscheofficial/cookie-consent-banner-react';
import { useGetCookieConsentSettings } from '../../hooks';

/**
 * Documentation https://github.com/porscheofficial/cookie-consent-banner/tree/main/packages/cookie-consent-banner-react
 */

const initConsent = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  acceptedCategories,
}: {
  acceptedCategories: string[];
}) => {
  /**
   * Init services on consent, the consented cookies will be injected thruough `acceptedCategories` string Array
   * acceptedCategories.includes("analytics")
   * acceptedCategories.includes("marketing")
   * etc.
   */
};

export const CookieConsent = () => {
  const cookieConsentSettings = useGetCookieConsentSettings();
  const bodyTextDelimiter = '{settings}';
  const bodyTextArray = cookieConsentSettings.body.split(bodyTextDelimiter);

  return (
    <CookieConsentBanner
      handlePreferencesUpdated={initConsent}
      handlePreferencesRestored={initConsent}
      btnLabelAcceptAndContinue={cookieConsentSettings.acceptAndContinue}
      btnLabelSelectAllAndContinue={cookieConsentSettings.selectAllAndContinue}
      btnLabelOnlyEssentialAndContinue={
        cookieConsentSettings.onlyEssentialAndContinue
      }
      btnLabelPersistSelectionAndContinue={
        cookieConsentSettings.persistSelectionAndContinue
      }
      contentSettingsDescription={cookieConsentSettings.settingsDescription}
      availableCategories={[
        {
          description: cookieConsentSettings.technicalCookiesDescription,
          key: 'technically_required',
          label: cookieConsentSettings.technicalCookiesLabel,
          isMandatory: true,
        },
        {
          description: cookieConsentSettings.analyticsCookiesDescription,
          key: 'analytics',
          label: cookieConsentSettings.analyticsCookiesLabel,
        },
        {
          description: cookieConsentSettings.marketingCookiesDescription,
          key: 'marketing',
          label: cookieConsentSettings.marketingCookiesLabel,
        },
      ]}
    >
      {bodyTextArray[0]}
      <button
        type="button"
        style={{
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          border: 'none',
          padding: 0,
          color: '#fff',
          cursor: 'pointer',
          fontFamily: 'effra, sans-serif',
          fontSize: '15.75px',
        }}
        onClick={() => triggerCookieConsentBanner({ showDetails: true })}
        onKeyPress={() => triggerCookieConsentBanner({ showDetails: true })}
      >
        {bodyTextArray[1]}
      </button>
      {bodyTextArray[2]}
      &nbsp;
      <a
        href="https://bmisverige.se/sektretesspolicy-vid-tredje-part"
        target={'_blank'}
        style={{ color: '#fff', textDecoration: 'underline' }}
        rel="noreferrer"
      >
        här
      </a>
      .
    </CookieConsentBanner>
  );
};
