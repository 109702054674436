import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiClient } from '../../api';
import { ApiCreateOrder } from '../../api/interfaces';
import { CheckoutState } from '../interfaces';
import { RootState } from '../store';
import { setApiError } from './api-error-slice';

/** Async THUNK's  https://redux-toolkit.js.org/api/createAsyncThunk */
export const createApiOrder = createAsyncThunk(
  'checkout/createApiOrder',
  async (createOrder: ApiCreateOrder, thunkApi) => {
    try {
      const createdOrder = await ApiClient.sendOrder(createOrder.order);
      return createdOrder;
    } catch (e: unknown) {
      let errorMessage;
      if (e instanceof Error) {
        errorMessage = e.message;
      } else {
        errorMessage = String(e);
      }
      thunkApi.dispatch(setApiError({ errorOccured: true, errorMessage }));
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

const initialState: CheckoutState = {
  order: undefined,
  orderStatus: 'IDLE',
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setOrderStateIdle: (state) => {
      state.orderStatus = 'IDLE';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createApiOrder.fulfilled, (state) => {
        state.orderStatus = 'DONE';
        state.order = undefined;
      })
      .addCase(createApiOrder.pending, (state, action) => {
        state.orderStatus = 'PROCESSING';
        const { arg } = action.meta;
        state.order = arg.order;
      })
      .addCase(createApiOrder.rejected, (state, action) => {
        state.orderStatus = 'ERROR';
        const { arg } = action.meta;
        state.order = arg.order;
      });
  },
});

export const { setOrderStateIdle } = checkoutSlice.actions;

export const selectOrder = (state: RootState) => state.checkout.order;
export const selectOrderStatus = (state: RootState) =>
  state.checkout.orderStatus;

export type OrderApiStatus = 'IDLE' | 'PROCESSING' | 'DONE' | 'ERROR';

export default checkoutSlice.reducer;
