import { Box, Container } from '@mui/material';
import React from 'react';
import { useGetFramework } from '../../hooks';
import { FooterAddress } from './footer-address';
import * as styles from './footer.module.scss';

export const Footer = () => {
  const frameWork = useGetFramework();

  return (
    <footer className={styles.footer}>
      <Container className={styles.footerWrapper}>
        <Box className={styles.footerWrapperColumn}>
          <FooterAddress officeAddress={frameWork.office} />
        </Box>
        <Box className={styles.footerWrapperColumn}>
          {/*
          BEB-255 - commented footerlinks
          <FooterLinks
            links={frameWork.links}
            title={frameWork.titleShortcuts}
          />
          */}
        </Box>
      </Container>
    </footer>
  );
};
