import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { ContentfulCookieConsentSettings } from '../contentful';

export const useGetCookieConsentSettings =
  (): ContentfulCookieConsentSettings => {
    const data = useStaticQuery(graphql`
      query {
        contentfulCookieConsentSettings {
          ...CookieConsentSettings
        }
      }
    `);

    return data.contentfulCookieConsentSettings;
  };
