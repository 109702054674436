import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorState } from '../interfaces';
import { SetApiError } from '../interfaces/set-api-error-action.interface';
import { RootState } from '../store';

const initialState: ApiErrorState = {
  error: false,
  message: '',
};

export const errorSlice = createSlice({
  name: 'apiError',
  initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<SetApiError>) => {
      state.error = action.payload.errorOccured;
      state.message = action.payload.errorMessage;
    },
    removeApiError: (state) => {
      state.error = false;
      state.message = '';
    },
  },
});

export const { setApiError } = errorSlice.actions;
export const { removeApiError } = errorSlice.actions;

export const selectApiError = (state: RootState) => state.apiError;

export default errorSlice.reducer;
