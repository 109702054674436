import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user-slice';
import shopReducer from './slices/shop-slice';
import checkoutReducer from './slices/checkout-slice';
import apiErrorReducer from './slices/api-error-slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    shop: shopReducer,
    checkout: checkoutReducer,
    apiError: apiErrorReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
