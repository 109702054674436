import { Modal, Box, Typography, Button } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { RootState } from '../../store';
import { removeApiError } from '../../store/slices';
import * as styles from './api-error-notification.module.scss';

const mapStateToProps = (state: RootState) => {
  return { error: state.apiError.error };
};

interface ApiErrorNotificationProps {
  error: boolean;
}

const ApiErrorNotification = ({ error }: ApiErrorNotificationProps) => {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(removeApiError());
  };

  return (
    <Modal
      open={error}
      onClose={handleClose}
      aria-labelledby="error-title"
      aria-describedby="error-description"
    >
      <Box className={styles.ErrorModalBody}>
        <Typography id="error-title" variant="h6" component="h2">
          Ett fel har inträffat
        </Typography>
        <Typography id="error-description" sx={{ mt: 2 }} component="p">
          Tyvärr uppstod ett problem. Försök igen och kontakta kundtjänst om
          problemet kvarstår.
        </Typography>
        <div className={styles.ErrorModalButtonContainer}>
          <Button onClick={handleClose}>Stäng</Button>
        </div>
      </Box>
    </Modal>
  );
};

export default connect(mapStateToProps)(ApiErrorNotification);
