import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetEcomCommon } from '../../hooks';
import { RichText } from '../rich-text';
import * as styles from './customer-missing.module.scss';

export const CustomerMissing = () => {
  const ecomCommon = useGetEcomCommon();

  useEffect(() => {
    window.localStorage.clear();
  });

  return (
    <Box className={styles.customerMissing}>
      <Box
        className={styles.customerMissingMessage}
        sx={(theme) => ({
          boxShadow: theme.shadows[5],
        })}
      >
        <Typography component="h1" variant="h1">
          {ecomCommon.missingCustomerNumberTitle}
        </Typography>
        <RichText
          data={{
            richText: {
              raw: ecomCommon.missingCustomerNumberText.raw,
              references: [],
            },
          }}
        />
      </Box>
    </Box>
  );
};
