exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kassa-tsx": () => import("./../../../src/pages/kassa.tsx" /* webpackChunkName: "component---src-pages-kassa-tsx" */),
  "component---src-pages-konto-tsx": () => import("./../../../src/pages/konto.tsx" /* webpackChunkName: "component---src-pages-konto-tsx" */),
  "component---src-pages-kundvagn-tsx": () => import("./../../../src/pages/kundvagn.tsx" /* webpackChunkName: "component---src-pages-kundvagn-tsx" */),
  "component---src-pages-order-bekraftelse-tsx": () => import("./../../../src/pages/order-bekraftelse.tsx" /* webpackChunkName: "component---src-pages-order-bekraftelse-tsx" */),
  "component---src-pages-order-historik-tsx": () => import("./../../../src/pages/order-historik.tsx" /* webpackChunkName: "component---src-pages-order-historik-tsx" */),
  "component---src-pages-sok-tsx": () => import("./../../../src/pages/sok.tsx" /* webpackChunkName: "component---src-pages-sok-tsx" */),
  "component---src-templates-article-pages-tsx": () => import("./../../../src/templates/article-pages.tsx" /* webpackChunkName: "component---src-templates-article-pages-tsx" */),
  "component---src-templates-common-pages-tsx": () => import("./../../../src/templates/common-pages.tsx" /* webpackChunkName: "component---src-templates-common-pages-tsx" */),
  "component---src-templates-product-category-page-tsx": () => import("./../../../src/templates/product-category-page.tsx" /* webpackChunkName: "component---src-templates-product-category-page-tsx" */)
}

