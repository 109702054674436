import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { HeaderNavigationMenuData } from '../components/header-navigation-menu-list';
import {
  ContentfulFramework,
  ContentfulProductCategoryPage,
} from '../contentful';

export const useGetNavigationMenuData = (): HeaderNavigationMenuData[] => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageProductCategory(sort: { fields: sortOrder }) {
        nodes {
          title
          id
          slug
          items {
            title
            id
            slug
          }
        }
      }
      contentfulFramework {
        ...Framework
      }
    }
  `);

  const framework: ContentfulFramework = data.contentfulFramework;
  const productCategoryData: ContentfulProductCategoryPage[] =
    data.allContentfulPageProductCategory.nodes;

  const productTree: HeaderNavigationMenuData[] = [
    {
      title: framework.productsMenuText,
      isPageLink: false,
      menuTree: [
        { title: framework.menuMobileHeader, isHeader: true },
        ...productCategoryData.map((productItem) => {
          return {
            title: productItem.title,
            subMenu: [
              {
                title: framework.menuMobileHeader,
                isHeader: true,
                isBackLink: true,
              },
              ...productItem.items.map((subItem) => {
                return {
                  title: subItem.title,
                  slug: subItem.slug,
                };
              }),
            ],
          };
        }),
      ],
    },
  ];

  const menuTree: HeaderNavigationMenuData[] = framework.menuItems.map(
    (menuItem) => {
      return {
        title: menuItem.title,
        isPageLink: true,
        menuTree: [{ title: menuItem.title, slug: menuItem.slug }],
      };
    },
  );

  return [...productTree, ...menuTree];
};
