import React from 'react';
import { useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { selectCartQuantity } from '../../store/slices';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Badge, IconButton } from '@mui/material';
import { navigate } from 'gatsby';
import * as styles from './mini-cart.module.scss';

export const MiniCart = () => {
  const cartQuantity = useAppSelector((state: RootState) =>
    selectCartQuantity(state),
  );
  return (
    <IconButton onClick={() => navigate('/kundvagn')} className={styles.button}>
      <Badge
        badgeContent={cartQuantity}
        max={99}
        classes={{ badge: styles.badge }}
        color="error"
      >
        <ShoppingCartOutlinedIcon sx={{ fontSize: '1.2rem' }} />
      </Badge>
    </IconButton>
  );
};
