import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { ContentfulArticlePage } from '../contentful';

export const useGetArticleList = (): ContentfulArticlePage[] => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageArticle {
        nodes {
          ...ArticleCard
        }
      }
    }
  `);

  return data.allContentfulPageArticle.nodes;
};
