import { MutableRefObject, useEffect } from 'react';

export function useOnClickOutside<T>(
  ref: MutableRefObject<null | (T & { contains: (node: Node) => boolean })>,
  handler: () => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (event && event.target) {
        if (!ref.current || ref.current?.contains(event.target as Node)) {
          return;
        }
        handler();
      }
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}
