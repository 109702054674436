import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useState } from 'react';

export interface FormState {
  [name: string]: string;
}
export const useForm = (initialState: FormState) => {
  const [values, setValues] = useState<FormState>(initialState);

  const onChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => {
    const { value, name } = event.target;

    setValues({ ...values, [name]: value });
  };

  return { onChange, values, setValues };
};
