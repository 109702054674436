import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '../interfaces';
import { RootState } from '../store';

const initialState: UserState = {
  email: undefined,
  name: undefined,
  customerNumber: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setCustomerNumber: (state, action: PayloadAction<number>) => {
      state.customerNumber = action.payload;
    },
  },
});

export const { setCustomerNumber } = userSlice.actions;
export const { setEmail } = userSlice.actions;
export const { setName } = userSlice.actions;

export const selectEmail = (state: RootState) => state.user.email;
export const selectName = (state: RootState) => state.user.name;
export const selectCustomerNumber = (state: RootState) =>
  state.user.customerNumber;
export const selectCustomerInformation = (state: RootState) => state.user;

export default userSlice.reducer;
