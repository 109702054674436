import { Typography } from '@mui/material';
import React from 'react';
import { ContentfulOffice } from '../../../contentful';
import { RichText } from '../../rich-text';

interface FooterAddressProps {
  officeAddress?: ContentfulOffice;
}
export const FooterAddress = ({ officeAddress }: FooterAddressProps) => {
  return (
    <>
      <Typography component="h2" variant="subtitle1">
        {officeAddress?.title}
      </Typography>
      <RichText
        data={{
          richText: { raw: officeAddress?.address?.raw ?? '', references: [] },
        }}
      />
      <p>
        {officeAddress?.labelVisitorAddress} {officeAddress?.visitorAddress}
        <br />
        {officeAddress?.labelOrganizationNumber}{' '}
        {officeAddress?.organizationNumber}
      </p>

      {officeAddress?.phone && (
        <p>
          {officeAddress?.labelPhone}{' '}
          <a href={'tel:' + officeAddress?.phone.replace(/\D/g, '')}>
            {/* Only keep digits */}
            {officeAddress?.phone}
          </a>
        </p>
      )}

      {officeAddress?.email && (
        <p>
          {officeAddress?.labelEmail}{' '}
          <a href={'mailto:' + officeAddress?.email}>{officeAddress?.email}</a>
        </p>
      )}
    </>
  );
};
