import React from 'react';
import { HeaderNavigationMenu } from '../header-navigation-menu';
import { Paper } from '@mui/material';
import { HeaderServiceMenu } from '../header-service-menu';

export const Header = () => {
  return (
    <Paper elevation={4} component="header">
      <HeaderServiceMenu></HeaderServiceMenu>
      <HeaderNavigationMenu></HeaderNavigationMenu>
    </Paper>
  );
};
