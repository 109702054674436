import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { ContentfulCustomer } from '../contentful';

export const useGetCustomers = (): ContentfulCustomer[] => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomer {
        nodes {
          ...CustomerInformation
        }
      }
    }
  `);

  return data.allContentfulCustomer.nodes;
};
