import {
  Backdrop,
  Box,
  Container,
  IconButton,
  Slide,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import * as styles from './header-navigation-menu.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { HeaderLogoLink } from '../header-logo-link';
import { useGetNavigationMenuData, useOnClickOutside } from '../../hooks';
import { HeaderNavigationMenuList } from '../header-navigation-menu-list';
import { navigate } from 'gatsby';
import { SearchField } from '../search-field';

export const HeaderNavigationMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigationData = useGetNavigationMenuData();

  const htmlBodyElement =
    typeof document !== 'undefined'
      ? document.querySelector('body')
      : undefined;

  const setClassOnBodyElement = (
    classValue: string,
    method: 'add' | 'remove',
  ) => {
    if (!htmlBodyElement) {
      return;
    }

    htmlBodyElement.classList[method](classValue);
  };

  const closeMenu = () => {
    setClassOnBodyElement(styles.MenuIsOpen, 'remove');
    setMenuIsOpen(false);
  };

  const toggleMenu = () => {
    if (typeof menuIsOpen === 'number' || menuIsOpen === true) {
      closeMenu();
    } else {
      setClassOnBodyElement(styles.MenuIsOpen, 'add');
      setMenuIsOpen(!menuIsOpen);
    }
  };

  const [menuIsOpen, setMenuIsOpen] = useState<number | boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const ref = useRef<null | HTMLDivElement>(null);

  useOnClickOutside<HTMLDivElement>(ref, () => {
    closeMenu();
  });

  return (
    <div ref={ref}>
      <Box component="div" className={styles.navigationBar}>
        <Container>
          <Box component="div" className={styles.navigationBarContent}>
            <HeaderLogoLink closeMenuFn={closeMenu} />
            {!isMobile && (
              <Box component="nav" role="navigation" aria-label="Navigation">
                <Tabs
                  scrollButtons={false}
                  value={menuIsOpen === true ? 0 : menuIsOpen}
                  variant="scrollable"
                >
                  {navigationData.map((menuItem, index) =>
                    !menuItem.isPageLink ? (
                      <Tab
                        iconPosition="end"
                        className={styles.navItem}
                        label={menuItem.title}
                        icon={<KeyboardArrowDown />}
                        onClick={toggleMenu}
                        key={`menu-item-${index}`}
                      />
                    ) : (
                      <Tab
                        label={menuItem.title}
                        className={styles.navItem}
                        key={`menu-item-${index}`}
                        onClick={() => {
                          closeMenu();
                          navigate(`/${menuItem.menuTree[0].slug}`);
                        }}
                      />
                    ),
                  )}
                </Tabs>
              </Box>
            )}

            {isMobile && (
              <div>
                <IconButton
                  onClick={() => setShowSearch(!showSearch)}
                  color="primary"
                >
                  <SearchIcon />
                </IconButton>
                <IconButton onClick={toggleMenu} color="primary">
                  <MenuIcon />
                </IconButton>
              </div>
            )}
          </Box>
        </Container>
      </Box>
      <Backdrop
        className={styles['backdrop']}
        open={menuIsOpen !== false && isMobile}
        onClick={toggleMenu}
      />
      <Slide direction="down" in={menuIsOpen !== false}>
        <Box className={styles.drawer}>
          <IconButton className={styles.menuCloseButton} onClick={toggleMenu}>
            <CloseIcon />
          </IconButton>
          <Container component="nav">
            <HeaderNavigationMenuList
              isMobile={isMobile}
              items={navigationData}
              toggleMenu={toggleMenu}
            />
          </Container>
        </Box>
      </Slide>
      {isMobile && showSearch && <SearchField />}
    </div>
  );
};
