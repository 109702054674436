import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { ContentfulEcomCommon } from '../contentful';

export const useGetEcomCommon = (): ContentfulEcomCommon => {
  const data = useStaticQuery(graphql`
    query {
      contentfulEcomCommon {
        ...EcomCommon
      }
    }
  `);

  return data.contentfulEcomCommon;
};
