import React, { useEffect, useRef, useState } from 'react';
import {
  HeaderNavigationMenuData,
  HeaderNavigationMenuListItem,
} from './header-navigation-menu-list-item.interface';
import * as styles from './header-navigation-menu-list.module.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Typography, Box } from '@mui/material';
import { navigate } from 'gatsby';
import { useOnClickOutside } from '../../hooks';

export interface HeaderNavigationMenuListProps {
  items: HeaderNavigationMenuData[];
  isMobile: boolean;
  toggleMenu: () => void;
}

export const HeaderNavigationMenuList = ({
  items,
  isMobile,
  toggleMenu,
}: HeaderNavigationMenuListProps) => {
  interface MenuProps {
    item: HeaderNavigationMenuListItem;
    isMobile: boolean;
  }
  const Menu = ({ item, isMobile }: MenuProps) => {
    const [showSubmenu, setShowSubmenu] = useState<boolean>(false);

    const onClick = () => {
      setShowSubmenu(!showSubmenu);
    };

    const ref = useRef<null | HTMLLIElement>(null);

    useOnClickOutside<HTMLLIElement>(ref, () => setShowSubmenu(false));

    return (
      <li className={styles.MenuListItem} ref={ref}>
        <Button
          onClick={onClick}
          endIcon={<ChevronRightIcon />}
          className={joinCssClasses([
            styles.MenuListItemButton,
            showSubmenu ? styles.MenuListItemButtonActive : '',
          ])}
        >
          {item.title}
        </Button>
        <SubMenu
          show={showSubmenu}
          subItems={item.subMenu}
          isMobile={isMobile}
        />
      </li>
    );
  };

  interface MenuHeaderProps {
    showBackButton: boolean;
    headerTitle: string;
    setVisibility?: (b: boolean) => void;
  }
  const MenuHeader = ({
    showBackButton,
    headerTitle,
    setVisibility,
  }: MenuHeaderProps) => {
    return (
      <Box component="li" className={styles.MenuListHeading}>
        {showBackButton ? (
          <Button
            startIcon={<ChevronLeftIcon />}
            className={styles.MenuListItemBackButton}
            onClick={() => setVisibility && setVisibility(false)}
          >
            {headerTitle}
          </Button>
        ) : (
          <Typography variant="h6" component="h6">
            {headerTitle}
          </Typography>
        )}
      </Box>
    );
  };

  interface SubMenuProps {
    subItems?: HeaderNavigationMenuListItem[];
    show: boolean;
    isMobile: boolean;
  }
  const SubMenu = ({ subItems, show, isMobile }: SubMenuProps) => {
    const [isVisible, setVisibility] = useState<boolean>();

    useEffect(() => {
      setVisibility(show);
    }, [show]);

    if (!isVisible) return null;

    const onClick = (slug?: string) => {
      toggleMenu();
      navigate(slug ? `/${slug}` : '');
    };

    return (
      <div
        className={joinCssClasses([styles.MenuList, show ? styles.show : ''])}
      >
        <ul>
          {subItems?.map((subItem, index) => (
            <li key={`sub-navigaiton-${index}`} className={styles.MenuListItem}>
              {subItem.isHeader && subItem.isHeader && isMobile ? (
                <MenuHeader
                  headerTitle={subItem.title}
                  showBackButton={subItem.isBackLink ?? false}
                  setVisibility={setVisibility}
                />
              ) : (
                !subItem.isHeader && (
                  <Button
                    onClick={() => onClick(subItem?.slug)}
                    className={styles.MenuListItemLinkButton}
                  >
                    {subItem.title}
                  </Button>
                )
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  interface MenuLinksProps {
    linkItems: HeaderNavigationMenuData[];
  }
  const MenuLinks = ({ linkItems }: MenuLinksProps) => {
    const onClick = (slug?: string) => {
      toggleMenu();
      navigate(slug ? `/${slug}` : '');
    };

    return (
      <ul className={styles.MenuListItemLinkList}>
        {linkItems.map((linkItem) =>
          linkItem.menuTree.map((item, index) => (
            <li key={`navigation-link-${index}`}>
              <Button
                className={styles.MenuListItemButton}
                onClick={() => onClick(item.slug)}
              >
                {item.title}
              </Button>
            </li>
          )),
        )}
      </ul>
    );
  };

  const joinCssClasses = (classes: string[]): string => classes.join(' ');
  const menu = items.filter((it) => !it.isPageLink);
  const links = items.filter((it) => it.isPageLink);

  return (
    <>
      <ul className={joinCssClasses([styles.MenuList, styles.show])}>
        {menu.map((menuItem) =>
          menuItem.menuTree.map((item, index) =>
            isMobile && item.isHeader ? (
              <MenuHeader
                key={`navigaiton-${index}`}
                headerTitle={item.title}
                showBackButton={false}
              />
            ) : (
              !item.isHeader && (
                <Menu
                  key={`navigaiton-${index}`}
                  item={item}
                  isMobile={isMobile}
                />
              )
            ),
          ),
        )}
        {isMobile && <MenuLinks linkItems={links} />}
      </ul>
    </>
  );
};
