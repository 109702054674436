import { useAppDispatch, useGetCustomers } from '../../hooks';
import {
  getApiUserDataByEmail,
  setCustomerNumber,
  setEmail,
  setName,
} from '../../store/slices';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from '../layout';
import { LinearProgress } from '@mui/material';
import { CustomerMissing } from '../customer-missing';
import { WelcomeScreen } from '../welcome-screen';
import { security } from '../../api';

interface AppWrapperProps {
  element: JSX.Element;
}
export const AppWrapper = ({ element }: AppWrapperProps) => {
  const dispatch = useAppDispatch();
  const allCustomers = useGetCustomers();

  const { error, isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();

  security.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getApiUserDataByEmail({ email: user?.email ?? '' }));
    }
  }, [isAuthenticated, user, dispatch]);

  if (isLoading) {
    return <LinearProgress color="primary" />;
  }

  if (error) {
    return (
      <div>
        Tyvärr uppstod ett problem. Försök igen och kontakta kundtjänst om
        problemet kvarstår <p>{error.message}</p>
      </div>
    );
  }

  if (isAuthenticated) {
    //Get user info from Auth0 claims
    const email = user?.email ?? '';
    const customerNumber =
      user?.['https://bmigroup.com/sap_customer_number'] ?? '';
    const userName = user?.name ?? '';

    const loggedInCustomer = allCustomers.find(
      (customer) => customer.customerNumber === customerNumber,
    );

    /**
     *  If the logged in user doesent have a customer number, OR the customer
     * number doesent exist in Contenful list of users, return a information screen
     */
    if (!loggedInCustomer) {
      return <CustomerMissing />;
    }

    //Update redux store
    dispatch(setEmail(email));
    dispatch(setCustomerNumber(customerNumber));
    dispatch(setName(userName));

    return <Layout>{element}</Layout>;
  } else {
    return <WelcomeScreen />;
  }
};
