import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiCartItem,
  ApiClient,
  ApiUpdateUserCart,
  ApiUpdateUserFavourites,
  ApiUser,
} from '../../api';
import { RootState } from '../store';
import {
  ShopState,
  UpdateApiUserCart,
  CartItem,
  GetApiUser,
  SearchApiOrders,
} from '../interfaces';
import { UpdateApiUserFavourites } from '../interfaces/update-api-user-favourites.interface';
import { setApiError } from './api-error-slice';

/** Async THUNK's  https://redux-toolkit.js.org/api/createAsyncThunk */
export const getApiUserDataByEmail = createAsyncThunk(
  'shop/getApiUserDataByEmail',
  async (toGet: GetApiUser, thunkApi) => {
    try {
      const user = await ApiClient.getUser(toGet.email);
      return user;
    } catch (e: unknown) {
      let errorMessage;
      if (e instanceof Error) {
        errorMessage = e.message;
      } else {
        errorMessage = String(e);
      }
      thunkApi.dispatch(
        setApiError({ errorOccured: true, errorMessage: errorMessage }),
      );
    }
  },
);

export const updateApiUserFavourites = createAsyncThunk(
  'shop/updateApiUserFavourites',
  async (update: UpdateApiUserFavourites, thunkApi) => {
    switch (update.operation) {
      case 'ADD': {
        thunkApi.dispatch(addFavourite(update.favourite));
        break;
      }
      case 'REMOVE': {
        thunkApi.dispatch(removeFavourite(update.favourite));
        break;
      }
    }

    const state = thunkApi.getState() as RootState;

    const stateFavourites = selectFavourites(state);

    const userToUpdate: ApiUpdateUserFavourites = {
      emailAddress: state.user.email,
      name: state.user.name,
      favoriteArticles: stateFavourites,
    };

    try {
      const responseUser = await ApiClient.updateUserFavourites(userToUpdate);
      return responseUser;
    } catch (e: unknown) {
      let errorMessage;
      if (e instanceof Error) {
        errorMessage = e.message;
      } else {
        errorMessage = String(e);
      }
      thunkApi.dispatch(
        setApiError({ errorOccured: true, errorMessage: errorMessage }),
      );
    }
  },
);

export const updateApiUserCart = createAsyncThunk(
  'shop/updateApiUserCart',
  async (update: UpdateApiUserCart, thunkApi) => {
    switch (update.operation) {
      case 'ADD': {
        thunkApi.dispatch(addCartItem(update.cartItem));
        break;
      }
      case 'REMOVE': {
        thunkApi.dispatch(removeCartItem(update.cartItem));
        break;
      }
      case 'UPDATE': {
        thunkApi.dispatch(updateCartItem(update.cartItem));
        break;
      }
      case 'EMPTY': {
        thunkApi.dispatch(setCartEmpty());
        break;
      }
    }
    const state = thunkApi.getState() as RootState;

    const stateCart = selectCart(state);

    const userToUpdate: ApiUpdateUserCart = {
      emailAddress: state.user.email,
      name: state.user.name,
      shoppingCart: stateCart,
    };

    try {
      const responseUser = await ApiClient.updateUserCart(userToUpdate);
      return responseUser;
    } catch (e: unknown) {
      let errorMessage;
      if (e instanceof Error) {
        errorMessage = e.message;
      } else {
        errorMessage = String(e);
      }
      thunkApi.dispatch(
        setApiError({ errorOccured: true, errorMessage: errorMessage }),
      );
    }
  },
);

export const searchApiOrders = createAsyncThunk(
  'shop/searchApiOrders',
  async (toGet: SearchApiOrders, thunkApi) => {
    try {
      const orders = await ApiClient.searchOrders(
        toGet.customerNumber,
        toGet.numberOfOrders,
      );
      return orders;
    } catch (e: unknown) {
      let errorMessage;
      if (e instanceof Error) {
        errorMessage = e.message;
      } else {
        errorMessage = String(e);
      }
      thunkApi.dispatch(
        setApiError({ errorOccured: true, errorMessage: errorMessage }),
      );
    }
  },
);

const initialState: ShopState = {
  favourites: [],
  cart: [],
  messageToCustomerService: '',
  orderHistory: [],
};

const addOrUpdateCartItem = (
  state: ShopState,
  newCartItem: CartItem,
): CartItem[] => {
  const oldCart: CartItem[] = [...state.cart];

  if (oldCart.length === 0) {
    return [...oldCart, newCartItem];
  }

  const newCart: CartItem[] = [];

  const isItemInCart: boolean =
    oldCart.filter(
      (inCartItem) => inCartItem.articleNumber === newCartItem.articleNumber,
    ).length > 0;

  if (!isItemInCart) {
    newCart.push(...oldCart, newCartItem);
  } else {
    const mappedCart = oldCart.map((inCartItem) => {
      if (inCartItem.articleNumber === newCartItem.articleNumber) {
        const updatedCartItem: CartItem = {
          ...inCartItem,
          quantity: (inCartItem.quantity += newCartItem.quantity),
        };
        return updatedCartItem;
      } else {
        return inCartItem;
      }
    });
    newCart.push(...mappedCart);
  }

  return newCart;
};

const updateItemQuantityInCart = (
  state: ShopState,
  updatedCartItem: CartItem,
) => {
  const oldCart: CartItem[] = [...state.cart];
  const itemIndex = oldCart.findIndex(
    (itemInCart) => itemInCart.articleNumber === updatedCartItem.articleNumber,
  );
  if (itemIndex > -1) {
    oldCart[itemIndex] = { ...updatedCartItem };
  }

  return oldCart;
};

const removeItemFromCart = (state: ShopState, itemToRemove: CartItem) => {
  const oldCart: CartItem[] = [...state.cart];
  const itemIndex = oldCart.findIndex(
    (itemInCart) => itemInCart.articleNumber === itemToRemove.articleNumber,
  );

  if (itemIndex > -1) {
    oldCart.splice(itemIndex, 1);
  }

  return oldCart;
};

const removeFavouriteFromState = (
  state: ShopState,
  favouriteEntryToRemove: string,
) => {
  const oldFavouriteList = [...state.favourites];
  const itemIndex = oldFavouriteList.indexOf(favouriteEntryToRemove);

  if (itemIndex > -1) {
    oldFavouriteList.splice(itemIndex, 1);
  }

  return oldFavouriteList;
};

const updateUserCartFromApi = (apiCart?: ApiCartItem[]): CartItem[] => {
  if (!apiCart) return [];

  return apiCart.map((apiCartItem) => {
    return {
      articleNumber: apiCartItem.articleNumber,
      articleTitle: apiCartItem.articleTitle,
      articleQuantityAndAreaExplanation:
        apiCartItem.articleQuantityAndAreaExplanation,
      quantity: apiCartItem.quantity,
      articleSalesUnit: apiCartItem.articleSalesUnit,
    };
  });
};

const createUserStateFromApi = (apiUser?: ApiUser): ShopState => {
  return {
    favourites: apiUser?.favoriteArticles ?? [],
    cart: updateUserCartFromApi(apiUser?.shoppingCart),
    messageToCustomerService: '',
    orderHistory: [],
  };
};

// Create store slice and setup reducers
export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    addMessageToCustomerService: (state, action: PayloadAction<string>) => {
      state.messageToCustomerService = action.payload;
    },
    removeMessageToCustomerService: (state) => {
      state.messageToCustomerService = '';
    },
    addFavourite: (state, action: PayloadAction<string>) => {
      state.favourites = Array.from(
        new Set([...state.favourites, action.payload]),
      );
    },
    removeFavourite: (state, action: PayloadAction<string>) => {
      state.favourites = removeFavouriteFromState(state, action.payload);
    },
    addCartItem: (state, action: PayloadAction<CartItem>) => {
      state.cart = addOrUpdateCartItem(state, action.payload);
    },
    removeCartItem: (state, action: PayloadAction<CartItem>) => {
      state.cart = removeItemFromCart(state, action.payload);
    },
    updateCartItem: (state, action: PayloadAction<CartItem>) => {
      state.cart = updateItemQuantityInCart(state, action.payload);
    },
    setCartEmpty: (state) => {
      state.cart = [];
    },
    setOrderHistoryEmpty: (state) => {
      state.orderHistory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApiUserDataByEmail.fulfilled, (state, action) => {
        const { favourites, cart } = createUserStateFromApi(action.payload);
        state.favourites = favourites;
        state.cart = cart;
      })
      .addCase(updateApiUserFavourites.fulfilled, (state, action) => {
        const user = action.payload;
        state.favourites = user?.favoriteArticles ?? [];
      })
      .addCase(updateApiUserCart.fulfilled, (state, action) => {
        state.cart = updateUserCartFromApi(action.payload?.shoppingCart);
      })
      .addCase(searchApiOrders.fulfilled, (state, action) => {
        state.orderHistory = action.payload ?? [];
      });
  },
});

// Redux actions
export const { addMessageToCustomerService } = shopSlice.actions;
export const { removeMessageToCustomerService } = shopSlice.actions;
export const { setOrderHistoryEmpty } = shopSlice.actions;

const { addFavourite } = shopSlice.actions;
const { removeFavourite } = shopSlice.actions;
const { setCartEmpty } = shopSlice.actions;
const { addCartItem } = shopSlice.actions;
const { updateCartItem } = shopSlice.actions;
const { removeCartItem } = shopSlice.actions;

// Redux selectors
export const selectFavourites = (state: RootState): string[] =>
  state.shop.favourites;
export const selectCart = (state: RootState): CartItem[] => state.shop.cart;
export const selectCartQuantity = (state: RootState): number =>
  state.shop.cart
    .map((cartItem) => cartItem.quantity)
    .reduce((curr, prev) => curr + prev, 0);
export const selectShopData = (state: RootState) => state.shop;
export const selectOrderHistory = (state: RootState) => state.shop.orderHistory;

export default shopSlice.reducer;
