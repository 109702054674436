import { IconButton, InputBase } from '@mui/material';
import React, { FormEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as styles from './search-field.module.scss';
import { FormState, useForm, useGetFramework } from '../../hooks';
import { navigate } from 'gatsby';
/**
 * Component is included both in HeaderNavigationMenu (for mobile) and in HeaderServiceMenu (for desktop)
 */

export const SearchField = () => {
  const framework = useGetFramework();
  const initialFormState: FormState = {
    query: '',
  };
  const { onChange, values, setValues } = useForm(initialFormState);

  const placeholder = framework.placeholderSearch;
  const searchResultPageSlug = 'sok';

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    setValues(initialFormState);
    navigate(`/${searchResultPageSlug}?query=${values.query}`);
  };

  return (
    <form
      className={styles.searchForm}
      onSubmit={onSubmit}
      data-testid="search-form"
    >
      <SearchIcon
        color="disabled"
        sx={{ fontSize: '1.2rem', margin: '0 0.5rem' }}
      />
      <InputBase
        className={styles.searchField}
        placeholder={placeholder}
        margin="none"
        name="query"
        value={values.query}
        onChange={onChange}
        autoComplete="off"
        inputProps={{
          className: styles.input,
        }}
      />
      <IconButton type="submit" disabled={values.query === ''} title="Sök">
        <ChevronRightIcon sx={{ fontSize: '1.2rem', marginLeft: 'auto' }} />
      </IconButton>
    </form>
  );
};
