import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { ContentfulFramework } from '../contentful';

export const useGetFramework = (): ContentfulFramework => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFramework {
        ...Framework
      }
    }
  `);

  return data.contentfulFramework;
};
