import { useAuth0 } from '@auth0/auth0-react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import React from 'react';
import { useAppSelector, useGetCustomers, useGetFramework } from '../../hooks';
import { RootState } from '../../store';
import { selectCustomerNumber, selectName } from '../../store/slices';
import { MiniCart } from '../mini-cart';
import * as styles from './header-service-menu.module.scss';
import { SearchField } from '../search-field';
import { Link } from 'gatsby';

export const HeaderServiceMenu = () => {
  const { logout } = useAuth0();
  const theme = useTheme();
  const customerList = useGetCustomers();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const framework = useGetFramework();
  const userName = useAppSelector((state: RootState) => selectName(state));
  const userCustomerNumber = useAppSelector((state: RootState) =>
    selectCustomerNumber(state),
  );
  const userCustomer = customerList.find(
    (customer) => customer.customerNumber === userCustomerNumber,
  );

  return (
    <div className={styles.container}>
      {!isMobile && <SearchField />}
      <div className={styles.menuContainer}>
        <Typography component="span" sx={{ fontSize: '14px', marginRight: 1 }}>
          <Link to="/konto">{userName}</Link> ({userCustomer?.customerName})
        </Typography>
        <ButtonUnstyled
          className={styles.logoutButton}
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          {framework.logOut}
        </ButtonUnstyled>
        <MiniCart />
      </div>
    </div>
  );
};
