import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { ContentfulRichText } from '../../contentful';

interface RichTextProps {
  data: ContentfulRichText;
}

// https://github.com/contentful/rich-text/issues/96
interface RenderParagraphProps {
  children?: ReactNode;
}

export const RichText = ({ data }: RichTextProps) => {
  const RenderParagraph = ({ children }: RenderParagraphProps) => (
    <p>{children}</p>
  );

  const options: Options = {
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={node.data.target.file.url}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        let linkAttributes = {};
        const host = window ? window.location.hostname : '';

        if (!node.data.uri.includes(host)) {
          linkAttributes = {
            target: '_blank',
            rel: 'noopener noreferrer',
          };
        }

        return (
          <a href={node.data.uri} {...linkAttributes}>
            {children}
          </a>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <Typography variant="h1" component="h1" sx={{ mt: 4 }}>
            {children}
          </Typography>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <Typography variant="h2" component="h2" sx={{ mt: 3 }}>
            {children}
          </Typography>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Typography variant="h3" component="h3" sx={{ mt: 2 }}>
            {children}
          </Typography>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <RenderParagraph>{children}</RenderParagraph>
      ),
    },
  };

  return (
    <Typography component="div">
      {renderRichText(data.richText, options)}
    </Typography>
  );
};
